//
// Families
//
@mixin Regular {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 400;
}

@mixin Bold {
  font-family: 'Poppins', Arial, sans-serif;
  font-weight: 600;
}

@mixin PoppinsBold {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
}

//
// Mixins
//
@mixin fontGenerator($style, $size, $height, $bottom) {
    font-size: rem-calc($size, $font-size-base);
    line-height: rem-calc($height, $font-size-base);
    margin-bottom: rem-calc($bottom);
    margin-top: 0;

    @if $style == "Regular" {
      @include Regular();
    }
    @else if $style == "Bold" {
      @include Bold();
    }
    @if $style == "Poppins" {
      @include PoppinsBold();
    }
    @content;
}

@mixin text32($bottom: 24) { @include fontGenerator("Regular", 32, 40, $bottom) }
@mixin text24($bottom: 16) { @include fontGenerator("Regular", 24, 32, $bottom) }
@mixin text20($bottom: 15) { @include fontGenerator("Regular", 20, 28, $bottom) }
@mixin text16($bottom: 12) { @include fontGenerator("Regular", 16, 24, $bottom) }
@mixin text14($bottom: 11) { @include fontGenerator("Regular", 14, 20, $bottom) }

@mixin text32Emphasis($bottom: 24) { @include fontGenerator("Poppins", 32, 40, $bottom) }
@mixin text24Emphasis($bottom: 16) { @include fontGenerator("Poppins", 24, 32, $bottom) }
@mixin text20Emphasis($bottom: 15) { @include fontGenerator("Poppins", 20, 28, $bottom) }
@mixin text16Emphasis($bottom: 12) { @include fontGenerator("Bold", 16, 24, $bottom) }
@mixin text14Emphasis($bottom: 11) { @include fontGenerator("Bold", 14, 20, $bottom) }

@mixin text32Italic($bottom: 24) { @include fontGenerator("Italic", 32, 40, $bottom) }
@mixin text24Italic($bottom: 16) { @include fontGenerator("Italic", 24, 32, $bottom) }
@mixin text20Italic($bottom: 15) { @include fontGenerator("Italic", 20, 28, $bottom) }
@mixin text16Italic($bottom: 12) { @include fontGenerator("Italic", 16, 24, $bottom) }
@mixin text14Italic($bottom: 11) { @include fontGenerator("Italic", 14, 20, $bottom) }
