@tailwind base;
@tailwind components;
@tailwind utilities;

.maplibregl-popup-close-button {
  @apply mr-2 text-lg text-neutral-800 font-medium;
}

.maplibregl-popup-content {
  @apply p-4 rounded-md;
}
