@font-face {
  font-family: 'shippit-returns';
  src:
    url('../icons/shippit-returns.ttf?mt1jde') format('truetype'),
    url('../icons/shippit-returns.woff?mt1jde') format('woff'),
    url('../icons/shippit-returns.svg?mt1jde#shippit-returns') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'shippit-returns' !important;
  speak: none;
  font-size: 16px;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-minus:before {
  content: "\e907";
}
.icon-check:before {
  content: "\e901";
}
.icon-map-pin:before {
  content: "\e905";
}
.icon-plus:before {
  content: "\e906";
}
.icon-arrow-left:before {
  content: "\e900";
}
.icon-chevron-down:before {
  content: "\e902";
}
.icon-help-circle:before {
  content: "\e903";
}
.icon-close:before {
  content: "\e904";
}
