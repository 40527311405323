$font-size-base: 16px;
$baseline: 8px;

@import "./mixins";
@import "./type_generator";


//
// Heading levels
//
h1,
.h1 {
  @include text32Emphasis();
}

h2,
.h2 {
  @include text24Emphasis();
}

h5,
.h5 {
  @include text14Emphasis();
}

ul,
ol {
  @include text16();
}

p small {
  @include text14();
}

ul li,
ol li {
  padding-left: rem-calc($baseline);
  margin-left: rem-calc($baseline * 3);
  margin-bottom: 0;
}

b,
strong {
  @include PoppinsBold();
}

//
// Text classes
//
.text32--emphasis,
.text32--emphasis a {
  @include text32Emphasis()
}
.text24--emphasis,
.text24--emphasis a {
  @include text24Emphasis()
}
.text20--emphasis,
.text20--emphasis a {
  @include text20Emphasis()
}
.text16--emphasis,
.text16--emphasis a {
  @include text16Emphasis()
}
.text14--emphasis,
.text14--emphasis a {
  @include text14Emphasis()
}

.text32--italic,
.text32--italic a {
  @include text32Italic()
}
.text24--italic,
.text24--italic a {
  @include text24Italic()
}
.text20--italic,
.text20--italic a {
  @include text20Italic()
}
.text16--italic,
.text16--italic a {
  @include text16Italic()
}
.text14--italic,
.text14--italic a {
  @include text14Italic()
}

.text32,
.text32 a {
  @include text32()
}
.text24,
.text24 a {
  @include text24()
}
.text20,
.text20 a {
  @include text20()
}
.text16,
.text16 a {
  @include text16()
}
.text14,
.text14 a {
  @include text14()
}

//
// Lighter shade for text.
//
.text16--secondary {
}
.text14--secondary {
}

.visually__hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
