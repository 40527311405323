.ReactModalPortal {
  position: relative;
  z-index: 6;
}

.ReactModalPortal > div {
  opacity: 0;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.ReactModal__Overlay {
  background: 0 0;
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background .3s ease-in-out;
  z-index: 9999;
}

.ReactModalPortal .ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModalPortal .ReactModal__Overlay {
  background: rgba(0,0,0,.45);
  transition: opacity .3s ease-in-out;

  &--after-open {
    opacity: 1;
  }

  &--before-close {
    opacity: 0;
  }
}

.ReactModal__Content {
  background: #fff;
  border: 0;
  border-radius: .125rem;
  box-shadow: 0 2px 4px rgba(0,0,0,.3);
  display: flex;
  flex-direction: column;
  left: 1rem;
  max-width: 420px;
  margin: auto;
  height: calc(100vh - 30%);
  outline: none;
  overflow: hidden;
  position: absolute;
  right: 1rem;
  top: 4rem;
  -webkit-transform: translate(0);
  transform: translate(0);
}

.ReactModal-innards {
  border-bottom: .125rem solid #fff;
  flex: 1;
  min-height: 50vh;
  padding: 0 1.5rem 1.5rem;
}

@media all and (min-width: 0px) and (max-width: 767px) {
  .ReactModal__Content {
    top: 0;
    bottom: 0;
    height: 100%;
    max-width: 100%;
    left: 0;
    right: 0;
    border-radius: 0;
  }

  .ReactModalPortal .ReactModal__Overlay {
    transition: all 500ms cubic-bezier(0.19, 0.4, 0.12, 1) 100ms;
    transform: translateY(100%);
    opacity: 1;

    &--after-open {
      transform: translateY(0%);
    }

    &--before-close {
      transform: translateY(100%);
    }
  }
}