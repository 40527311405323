.react-autosuggest__container {
  position: relative;
  background-color: #fff;
}
.react-autosuggest__suggestions-container {
  background-color: #fff;
  box-shadow: 0 0.25rem 5px 0 rgba(0, 0, 0, 0.2);
  left: 0;
  right: 0;
  top: 74px;
  max-height: 190px;
  overflow: auto;
  position: absolute;
  z-index: 1;
}

.react-autosuggest__suggestions-container,
.react-autosuggest__suggestion,
.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
}

.react-autosuggest__suggestions-list {
  list-style: none;
}

.react-autosuggest__suggestion {
  box-sizing: border-box;
  cursor: pointer;
  height: 38px;
  line-height: 38px;
  padding: 0 16px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: rgba(8, 132, 252, 0.2);
}